import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
function Footer() {
  return (
    <>
      <footer>
          <hr></hr>
      </footer>
    </>
  );
}
export default Footer;
